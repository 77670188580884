html {
    --bg-very-dark: #191414;
    --bg-dark: #292424;
    --bg-dark-offset: #252020;
    --bg-medium: #494444;
    --bg-medium-offset: #454040;
    --fg-medium: #898484;
    --fg-light: #c9c4c4;
    --fg-hover: #f9f4f4;
    --primary: #a44;
    --primary-hover: #a11;

    --panel-bg: var(--bg-dark);
    --page-bg: var(--bg-very-dark);
    --listitem-bg: var(--bg-dark-offset);
    --listitem-hr: var(--bg-dark);
    --listitem-bg-hover: var(--bg-dark);
    --dialog-bg: var(--bg-medium);
    --dialog-listitem: var(--bg-medium-offset);
    --overlay-bg: var(--bg-dark);
    --sidebar-bg: var(--bg-medium);
    --sidebar-hr: var(--bg-very-dark);
    --scrollbar-track: #252020;
    --scrollbar-thumb: #2f2b2b;
    --errorbox-border: var(--bg-medium);
    --editor-border: var(--bg-medium);
    --editor-border-changed: var(--primary);

    --sidebar-width: 220px;
    --grid-width: 16px;
}

body {
    background: var(--page-bg);
    font-family: 'lato-regular';
    color: var(--fg-light);
}

::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: var(--bg-medium);
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal;
    font-family: 'lato-light';
}

.panels {
    display: flex;
    flex-direction: row;
}
.panel {
    background-color: var(--bg-dark);
    margin-right: 1px;
    min-width: 600px;
    width: 25vw;
    padding: var(--grid-width) calc(var(--grid-width) * 2);
    position: relative;
    transition: all 250ms;
    box-shadow: 0 0 var(--grid-width) rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
}

.panel h3.title {
    margin-top: 0;
    font-size: 28px;
    margin-right: calc(var(--grid-width) * 2);
    color: var(--fg-light);
    font-weight: normal;
    margin-bottom: calc(var(--grid-width) / 2);
}

.panel .zettelMeta {
    font-size: 13px;
    margin-bottom: calc(var(--grid-width) / 1);
    color: var(--fg-medium);
}

.panel .close {
    position: absolute;
    top: 10px;
    right: calc(var(--grid-width) * 2);
    font-size: 32px;
    cursor: pointer;
}

.panel .close:hover {
    color: var(--fg-light);
}

.panel .star {
    font-size: 32px;
    margin-left: var(--grid-width);
    color: gray;
    cursor: pointer;
}

.panel .starred {
    font-size: 32px;
    margin-left: var(--grid-width);
    color: #db0;
    cursor: pointer;
}

.panel.loading {
    opacity: 0.4;
    transform: scale(0.95);
    transform: scale(0.95);
}

.panel .panelContainer {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

.panel .panelContainer::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
}
.panel .panelContainer::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
}

.panelContainer code {
    margin: 10px 0;
    padding: 10px;
    display: inline-block;
    background-color: var(--bg-very-dark);
}

.panelContainer blockquote {
    padding: 0 var(--grid-width);
    padding-left: calc(var(--grid-width) * 2);
    margin: 0;
    border-left: 2px solid var(--bg-medium-offset);
    display: inline-block;
    font-size: 18px;
    font-style: italic;
    /* background-color: var(--bg-very-dark); */
}

.content {
    background-color: #eee;
    padding: var(--grid-width);
}

.content *:first-child {
    margin-top: 0;
}
dark .content > *:focus {
    outline: none;
    background-color: #fff;
}
.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    padding-left: var(--sidebar-width);
}

.sidebar {
    background-color: var(--sidebar-bg);
    width: var(--sidebar-width);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    color: var(--fg-light);
    box-shadow: 15px 0 15px rgba(0, 0, 0, 0.1);
}
.sidebar .entry {
    padding-bottom: 10px;
    cursor: pointer;
}

.sidebar hr {
    height: 1px;
    border: none;
    background-color: var(--sidebar-hr);
}

.sidebar h3 {
    margin-top: 0;
}

.sidebar .version {
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #777;
}

.overlay {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay .background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.8;
    background: var(--overlay-bg);
}

.overlay .bgimg {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.dialog {
    background: var(--dialog-bg);
    padding: var(--grid-width);
    z-index: 4;
    border-radius: 15px;
    color: var(--fg-light);
    position: relative;
}

.dialog h3 {
    margin-top: 0;
    padding-bottom: calc(var(--grid-width) / 2);
    border-bottom: 1px solid var(--fg-light);
}

.dialog h3.mt {
    margin-top: calc(var(--grid-width) * 2);
}

.dialog h3 .close {
    position: absolute;
    top: 5px;
    right: calc(var(--grid-width));
    font-size: 32px;
    cursor: pointer;
}

.dialog h3.close:hover {
    color: var(--fg-light);
}

.dialog .pwhelp {
    color: var(--fg-medium);
    font-size: 13px;
    margin-top: var(--grid-width);
}

.resultList {
    overflow-y: auto;
    margin-top: var(--grid-width);
}

.resultList::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
}
.resultList::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
}

.resultEntry {
    padding: 4px var(--grid-width);
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: var(--listitem-bg);
    box-sizing: border-box;
    border-bottom: 1px solid var(--listitem-hr);
}

/* .resultEntry:hover {
    background-color: var(--listitem-bg-hover);
} */
.resultEntry span {
    padding: 5px;
}

.resultEntry span:hover {
    color: var(--fg-hover);
    cursor: pointer;
}

.resultEntry .icon {
    font-size: 20px;
    padding: 0 12px;
}

.linkResultEntry {
    padding: 10px 10px;
    width: 100%;
    margin-top: 5px;
    background-color: var(--dialog-listitem);
    box-sizing: border-box;
}

.linkResultEntry h3 {
    margin-top: 0;
    cursor: pointer;
}

.linkResultEntry h3:hover {
    color: var(--fg-hover);
}

.linkSnippet {
    margin-top: calc(var(--grid-width) / 2);
    margin-left: var(--grid-width);
    font-size: 13px;
}

.historyEntry {
    margin-top: calc(var(--grid-width) / 2);
    padding: calc(var(--grid-width) / 2);
    background-color: var(--dialog-listitem);
    cursor: pointer;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.historyEntry:hover {
    background-color: var(--primary);
}

.addLinkEntry {
    margin-top: calc(var(--grid-width) / 2);
    padding: calc(var(--grid-width) / 2);
    background-color: var(--dialog-listitem);
    cursor: pointer;
    font-size: 13px;
}
.addLinkEntry.selected,
.addLinkEntry:hover {
    background-color: var(--primary);
}

.errorMsg {
    padding: var(--grid-width);
    border-radius: 8px;
    border: 1px solid var(--errorbox-border);
    color: var(--fg-light);
}

.dialog .errorMsg {
    padding: 0 0 var(--grid-width) 0;
    color: var(--primary);
}

.editor .CodeMirror {
    background: var(--bg-dark);
    color: var(--fg-light);
    border-color: var(--editor-border);
    transition: border 250ms;
}

.editor .editor-toolbar {
    border-color: var(--editor-border);
}

.editor .editor-toolbar .separator {
    border-color: var(--editor-border);
}

.editor .editor-toolbar button.active,
.editor .editor-toolbar button:hover {
    background-color: var(--primary);
    border: none;
}

.CodeMirror-scroll {
    /* max-height: calc(100vh - 250px); */
}

.editor.changed .CodeMirror {
    border-color: var(--editor-border-changed);
    border-style: dashed;
}

.flexrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.flexrow.spacing > * {
    margin-left: var(--grid-width);
}

.flexrow.flexend {
    justify-content: flex-end;
}
.flexrow.flexstart {
    justify-content: flex-start;
}
.flexrow.center {
    justify-content: center;
}

.mt {
    margin-top: 10px;
    box-sizing: border-box;
}

.mb {
    margin-bottom: 10px;
}

.p1 {
    padding: 16px;
}

.flexrow.spacebetween,
.flexcol.spacebetween {
    justify-content: space-between;
}

.flexcol {
    display: flex;
    flex-direction: column;
}

button {
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 250ms;
    /* font-weight: bold; */
    font-family: 'lato-regular';
}

button:focus {
    outline: none;
}
button:hover {
    background-color: var(--primary-hover);
}

button:disabled {
    background-color: var(--bg-medium);
}
button:disabled:hover {
    background-color: var(--bg-medium);
    cursor: default;
}

button.inactive {
    background-color: var(--bg-medium);
}

input,
select {
    background-color: #292424;
    color: #e9e4e4;
    border: 1px solid #595454;
    padding: 8px;
    font-family: 'lato-regular';
}

input:focus {
    outline: none;
}

a {
    color: var(--primary);
}
